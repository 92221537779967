import React from 'react'
import {SvgIcon} from "@material-ui/core";

function Help({className, color}) {
    return (
        <SvgIcon className={className} viewBox="0 0 12 22"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.47215 16.0519C5.80096 16.0519 5.22565 16.2849 4.74623 16.7276C4.26681 17.1703 4.0271 17.7295 4.0271 18.3819C4.0271 19.1042 4.29078 19.6867 4.79418 20.1061C5.2736 20.5022 5.82493 20.7119 6.47215 20.7119C7.0954 20.7119 7.64674 20.5022 8.12616 20.1061C8.60558 19.6867 8.86926 19.1042 8.86926 18.3819C8.86926 17.7295 8.62955 17.1703 8.1741 16.7276C7.71865 16.2849 7.14334 16.0519 6.47215 16.0519Z"
                fill={color ?? "#707070"}/>
            <path
                d="M12.393 3.42322C11.8417 2.63102 11.0746 1.97862 10.0918 1.51262C9.13294 1.06991 8.00631 0.836914 6.75981 0.836914C5.41744 0.836914 4.21889 1.11652 3.18814 1.65242C2.15739 2.18832 1.36635 2.91062 0.838984 3.74942C0.287652 4.58823 0.023971 5.45033 0 6.31243C0 6.82504 0.215739 7.31434 0.623245 7.71044C1.03075 8.12984 1.58208 8.33954 2.18136 8.33954C2.78063 8.33954 3.83536 8.10654 4.2908 6.61533C4.55449 5.84643 4.86611 5.28723 5.22567 4.91443C5.51332 4.61153 6.01672 4.44843 6.73584 4.44843C7.33512 4.44843 7.81454 4.61153 8.1741 4.93773C8.53367 5.26393 8.70146 5.61343 8.70146 6.07943C8.70146 6.28913 8.65352 6.49883 8.53367 6.68524C8.41381 6.89494 8.24602 7.10464 8.05425 7.29104C7.83851 7.50074 7.47894 7.80364 6.97555 8.24634C6.37628 8.75894 5.89686 9.20164 5.5373 9.57445C5.15376 9.99385 4.84214 10.4598 4.60243 10.9958C4.36272 11.5317 4.24286 12.1841 4.24286 12.9064C4.24286 13.5821 4.4586 14.118 4.86611 14.5141C5.27361 14.8869 5.75303 15.0733 6.32834 15.0733C7.431 15.0733 8.12616 14.4675 8.3419 13.3957C8.41381 13.0695 8.48573 12.7666 8.53367 12.6268C8.55764 12.5103 8.60558 12.3938 8.67749 12.3006C8.74941 12.1841 8.84529 12.0443 8.96515 11.9045C9.10897 11.7647 9.30074 11.5783 9.56442 11.322C10.4753 10.5297 11.2184 9.87735 11.6259 9.48125C12.0574 9.08514 12.417 8.59584 12.7286 8.05994C13.0402 7.47744 13.208 6.80174 13.208 6.05613C13.208 5.12413 12.9443 4.23873 12.393 3.42322Z"
                fill={color ?? "#707070"}/>
        </SvgIcon>
    )
};

export default Help;
