import React from 'react';
import {useGlobal} from 'reactn'
import {makeStyles} from "@material-ui/styles";
import styled from 'styled-components'
import {IconButton} from '@material-ui/core'
import {ReactComponent as MoreSvg} from "../../svgComponent/moreIcon.svg"

const ProjectName = styled.span`
    color: ${props => props.color || "#fff"};
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 5px;
    flex-grow: 1;
`

const ProjectIcon = styled.div`
    background: ${props => props.background || "#fff"};
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

`

function ProjectList(props) {
    const classes = useStyles()

    const [projects] = useGlobal('projects')
    return (
        <div className={classes.root}>
            {
                projects.map((pro) => {
                    return <div key={pro.id} className={classes.item}>
                        <ProjectIcon background={pro.color}>
                            <span className="material-icons material-icons-white">
                                {pro.icon}
                            </span>
                        </ProjectIcon>
                        <ProjectName color={pro.color}>{pro.name}</ProjectName>
                        <IconButton className={classes.button}>
                            <MoreSvg style={{fill: pro.color}}/>
                        </IconButton>
                    </div>
                })
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: 10,
        marginTop: 20
    },
    item: {
        display: "flex",
        padding: "0",
        backgroundColor: "#f1f1f1",
        marginBottom: 10,
        borderRadius: 10,
        alignItems: "center"
    },
    icon: {
        width: 34,
        border: "1px solid #fff",
        height: 34,
        borderRadius: 10,
    },
    button: {
        padding: 0,
        marginRight: 5
    }
}))
export default ProjectList;
