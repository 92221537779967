import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@material-ui/core'
import {makeStyles} from "@material-ui/styles";

function DialogContainer({
                             open, onClose, children, okText = "Save",
                             onOk, maxWidth = 'sm', disabled = false, title = ''
                         }) {
    const classes = useStyles()
    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={onClose}
            className={classes.dialog}
        >
            <IconButton className={classes.closeIcon}
                        onClick={onClose}
            >
                <img alt={'close'} src={'imgs/close.svg'}/>
            </IconButton>
            {
                title ?
                    <DialogTitle>{title}</DialogTitle>
                    :
                    null
            }
            <DialogContent className={classes.content}>
                {children}
            </DialogContent>
            <DialogActions>
                <Button fullWidth={true} variant={"contained"} className={classes.button}
                        disabled={disabled}
                        onClick={() => {
                            if (typeof onOk === "function")
                                onOk()
                            onClose()
                        }}
                >
                    {okText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-root': {
            overflow: "unset",
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center'
        }
    },

    closeIcon: {
        position: "absolute",
        top: -15,
        right: -15,
        backgroundColor: "#fff",
        cursor: "pointer",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        zIndex: 99,
        "&:hover": {
            backgroundColor: "#fff",
        },
        "&:focus": {
            backgroundColor: "#fff",
        }
    },
    content: {
        marginTop: 20
    },
    button: {
        backgroundColor: "#1790FF",
        color: "#fff",
        borderRadius: 20,
        boxShadow: "none",
        fontWeight: 700,
        "&:hover": {
            backgroundColor: "#1790FF",
            color: "#fff",
            opacity: 0.7,
            boxShadow: "none",
        },
    }
}))

export default DialogContainer;
