import {SvgIcon} from "@material-ui/core";

function MenuApp({className, color}) {
    return (
        <SvgIcon className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.18494 6.44288C4.09687 6.44288 4.83613 5.70362 4.83613 4.79169C4.83613 3.87977 4.09687 3.1405 3.18494 3.1405C2.27302 3.1405 1.53375 3.87977 1.53375 4.79169C1.53375 5.70362 2.27302 6.44288 3.18494 6.44288Z"
                fill="#707070"/>
            <path
                d="M7.69226 6.44288C8.60419 6.44288 9.34345 5.70362 9.34345 4.79169C9.34345 3.87977 8.60419 3.1405 7.69226 3.1405C6.78034 3.1405 6.04107 3.87977 6.04107 4.79169C6.04107 5.70362 6.78034 6.44288 7.69226 6.44288Z"
                fill={color ?? "#707070"}/>
            <path
                d="M12.1995 6.44288C13.1115 6.44288 13.8507 5.70362 13.8507 4.79169C13.8507 3.87977 13.1115 3.1405 12.1995 3.1405C11.2876 3.1405 10.5483 3.87977 10.5483 4.79169C10.5483 5.70362 11.2876 6.44288 12.1995 6.44288Z"
                fill={color ?? "#707070"}/>
            <path
                d="M16.7069 6.44288C17.6188 6.44288 18.358 5.70362 18.358 4.79169C18.358 3.87977 17.6188 3.1405 16.7069 3.1405C15.7949 3.1405 15.0557 3.87977 15.0557 4.79169C15.0557 5.70362 15.7949 6.44288 16.7069 6.44288Z"
                fill={color ?? "#707070"}/>
            <path
                d="M21.2588 6.44288C22.1707 6.44288 22.91 5.70362 22.91 4.79169C22.91 3.87977 22.1707 3.1405 21.2588 3.1405C20.3468 3.1405 19.6076 3.87977 19.6076 4.79169C19.6076 5.70362 20.3468 6.44288 21.2588 6.44288Z"
                fill={color ?? "#707070"}/>
            <path
                d="M3.18494 10.4593C4.09687 10.4593 4.83613 9.72004 4.83613 8.80811C4.83613 7.89618 4.09687 7.15692 3.18494 7.15692C2.27302 7.15692 1.53375 7.89618 1.53375 8.80811C1.53375 9.72004 2.27302 10.4593 3.18494 10.4593Z"
                fill={color ?? "#707070"}/>
            <path
                d="M7.69226 10.4593C8.60419 10.4593 9.34345 9.72004 9.34345 8.80811C9.34345 7.89618 8.60419 7.15692 7.69226 7.15692C6.78034 7.15692 6.04107 7.89618 6.04107 8.80811C6.04107 9.72004 6.78034 10.4593 7.69226 10.4593Z"
                fill={color ?? "#707070"}/>
            <path
                d="M12.1995 10.4593C13.1115 10.4593 13.8507 9.72004 13.8507 8.80811C13.8507 7.89618 13.1115 7.15692 12.1995 7.15692C11.2876 7.15692 10.5483 7.89618 10.5483 8.80811C10.5483 9.72004 11.2876 10.4593 12.1995 10.4593Z"
                fill={color ?? "#707070"}/>
            <path
                d="M16.7069 10.4593C17.6188 10.4593 18.358 9.72004 18.358 8.80811C18.358 7.89618 17.6188 7.15692 16.7069 7.15692C15.7949 7.15692 15.0557 7.89618 15.0557 8.80811C15.0557 9.72004 15.7949 10.4593 16.7069 10.4593Z"
                fill={color ?? "#707070"}/>
            <path
                d="M21.2588 10.4593C22.1707 10.4593 22.91 9.72004 22.91 8.80811C22.91 7.89618 22.1707 7.15692 21.2588 7.15692C20.3468 7.15692 19.6076 7.89618 19.6076 8.80811C19.6076 9.72004 20.3468 10.4593 21.2588 10.4593Z"
                fill={color ?? "#707070"}/>
            <path
                d="M3.18494 14.6988C4.09687 14.6988 4.83613 13.9595 4.83613 13.0476C4.83613 12.1357 4.09687 11.3964 3.18494 11.3964C2.27302 11.3964 1.53375 12.1357 1.53375 13.0476C1.53375 13.9595 2.27302 14.6988 3.18494 14.6988Z"
                fill={color ?? "#707070"}/>
            <path
                d="M7.69226 14.6988C8.60419 14.6988 9.34345 13.9595 9.34345 13.0476C9.34345 12.1357 8.60419 11.3964 7.69226 11.3964C6.78034 11.3964 6.04107 12.1357 6.04107 13.0476C6.04107 13.9595 6.78034 14.6988 7.69226 14.6988Z"
                fill={color ?? "#707070"}/>
            <path
                d="M12.1995 14.6988C13.1115 14.6988 13.8507 13.9595 13.8507 13.0476C13.8507 12.1357 13.1115 11.3964 12.1995 11.3964C11.2876 11.3964 10.5483 12.1357 10.5483 13.0476C10.5483 13.9595 11.2876 14.6988 12.1995 14.6988Z"
                fill={color ?? "#707070"}/>
            <path
                d="M16.7069 14.6988C17.6188 14.6988 18.358 13.9595 18.358 13.0476C18.358 12.1357 17.6188 11.3964 16.7069 11.3964C15.7949 11.3964 15.0557 12.1357 15.0557 13.0476C15.0557 13.9595 15.7949 14.6988 16.7069 14.6988Z"
                fill={color ?? "#707070"}/>
            <path
                d="M21.2588 14.6988C22.1707 14.6988 22.91 13.9595 22.91 13.0476C22.91 12.1357 22.1707 11.3964 21.2588 11.3964C20.3468 11.3964 19.6076 12.1357 19.6076 13.0476C19.6076 13.9595 20.3468 14.6988 21.2588 14.6988Z"
                fill={color ?? "#707070"}/>
            <path
                d="M3.18494 18.6496C4.09687 18.6496 4.83613 17.9103 4.83613 16.9984C4.83613 16.0865 4.09687 15.3472 3.18494 15.3472C2.27302 15.3472 1.53375 16.0865 1.53375 16.9984C1.53375 17.9103 2.27302 18.6496 3.18494 18.6496Z"
                fill={color ?? "#707070"}/>
            <path
                d="M7.69227 18.6496C8.6042 18.6496 9.34346 17.9103 9.34346 16.9984C9.34346 16.0865 8.6042 15.3472 7.69227 15.3472C6.78034 15.3472 6.04108 16.0865 6.04108 16.9984C6.04108 17.9103 6.78034 18.6496 7.69227 18.6496Z"
                fill={color ?? "#707070"}/>
            <path
                d="M12.1995 18.6496C13.1115 18.6496 13.8507 17.9103 13.8507 16.9984C13.8507 16.0865 13.1115 15.3472 12.1995 15.3472C11.2876 15.3472 10.5483 16.0865 10.5483 16.9984C10.5483 17.9103 11.2876 18.6496 12.1995 18.6496Z"
                fill={color ?? "#707070"}/>
            <path
                d="M16.7068 18.6496C17.6188 18.6496 18.358 17.9103 18.358 16.9984C18.358 16.0865 17.6188 15.3472 16.7068 15.3472C15.7949 15.3472 15.0557 16.0865 15.0557 16.9984C15.0557 17.9103 15.7949 18.6496 16.7068 18.6496Z"
                fill={color ?? "#707070"}/>
            <path
                d="M21.2588 18.6496C22.1707 18.6496 22.91 17.9103 22.91 16.9984C22.91 16.0865 22.1707 15.3472 21.2588 15.3472C20.3469 15.3472 19.6076 16.0865 19.6076 16.9984C19.6076 17.9103 20.3469 18.6496 21.2588 18.6496Z"
                fill={color ?? "#707070"}/>
        </SvgIcon>
    )
}

export default MenuApp;
