import {Avatar, Button, createStyles, Popover, Typography, withStyles,} from '@material-ui/core';
import {AccountCircle} from '@material-ui/icons';
import * as React from 'react';
import {useGlobal} from 'reactn'
import {LOGIN, LOGOUT_URL} from "../../config/apiUrl";
import axios from "axios";
import fireBase from "../../config/firebaseConfig";
// import { URL_MYACCOUNT } from '../../common/URLs';
// import store from '../../store';

/** Styles */
const styles = theme => createStyles({
    root: {
        padding: theme.spacing()
    },
    first: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing()
    },
    row: {
        display: 'flex',
    },
    nickname: {
        margin: 'auto 0 auto 1em'
    },
    toolLine: {
        display: "flex",
        justifyContentontent: "space-between",
    },
    popover: {
        zIndex: "9400 !important"
    }
});

function AccountPopover(props) {
    const {classes, anchorEl, handleClose} = props;
    const [user] = useGlobal('user')
    const isOpen = Boolean(anchorEl);

    const handleClickAccount = () => {
        window.location.href = LOGIN + '/account';
    }

    const handleClickChangeAccount = () => {
        window.location.href = LOGIN + '/login/select-account?redirect_url=' + window.location.href
    }

    const handleClickLogout = async () => {
        try {
            await fireBase.auth.signOut();
            await axios.post(LOGOUT_URL)
            window.location.assign(LOGIN + '/login?redirect_url=' + window.location.href)
        } catch (e) {
            console.log('error handleClickLogout')
        }
    }

    return (
        <Popover
            id="account"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            className={classes.popover}
        >
            <div className={classes.root}>
                <div className={classes.first}>
                    <div className={classes.row}>
                        <Avatar
                            src={user.avatar}
                        >
                            <AccountCircle/>
                        </Avatar>
                        <Typography variant="caption" className={classes.nickname}>{user.nickname}</Typography>
                    </div>
                    <div>
                        <Button variant="contained" color="primary" onClick={handleClickAccount}>アカウント</Button>
                    </div>
                </div>
                <div className={classes.toolLine}>
                    <div style={{marginRight:"10px"}}>
                        <Button variant="outlined" onClick={handleClickChangeAccount}>アカウントの切り替え</Button>
                    </div>
                    <div>
                        <Button variant="outlined" onClick={handleClickLogout}>ログアウト</Button>
                    </div>
                </div>
            </div>
        </Popover>
    )
}

/** アカウントを押下した時の動作です。 */

export default withStyles(styles)(AccountPopover);
