import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles'
import {Popover} from '@material-ui/core'
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from "moment";
import {FULLDAY_FORMAT} from "../../common/dateFormat";

function SelectDate({date = null, setDate}) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const modifiers = date ? {
        highlighted: new Date(moment(date).year(), moment(date).month(), moment(date).date()),
    } : {}
    return (
        <div>
            <div className={classes.root} onClick={(e) => setAnchorEl(e.currentTarget)}>
            <span className={classes.span}>
            {date ?? "un set"}
                </span>

            </div>
            <Popover open={Boolean(anchorEl)}
                     onClose={() => setAnchorEl(null)}
                     anchorEl={anchorEl}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'center',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'center',
                     }}
            >
                <div>
                    <DayPicker
                        modifiers={modifiers} onDayClick={day => {
                        setDate(moment(day).format(FULLDAY_FORMAT));
                        setAnchorEl(null)
                    }}
                        initialMonth={date ? new Date(moment(date).year(), moment(date).month()) : new Date()}
                    />
                </div>
            </Popover>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: 125,
        backgroundColor: "#f1f1f1",
        textAlign: "center",
        padding: "10px",
        borderRadius: 20,
        margin: "auto 10px",
        cursor: "pointer"
    },
    span: {}
}))
export default SelectDate;
