import fireBase from "../../config/firebaseConfig";
import {CATEGORIES_COLL, TASKS_COLL} from "../constants";


export const categoriesCollRef = (projectId) => {
    return fireBase.firestore.collection(TASKS_COLL)
        .doc(projectId)
        .collection(CATEGORIES_COLL)
}

export const categoryDocRef = (projectId, categoryId) => {
    return fireBase.firestore.collection(TASKS_COLL)
        .doc(projectId)
        .collection(CATEGORIES_COLL)
        .doc(categoryId)
}
