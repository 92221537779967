import fireBase from "../../config/firebaseConfig";
import {OUTPUT_TAKS_COLL, TASKS_COLL} from "../constants";


export const tasksCollRef = (projectId) => {
    return fireBase.firestore.collection(TASKS_COLL)
        .doc(projectId)
        .collection(OUTPUT_TAKS_COLL)
}

export const taskDocRef = (projectId, taskId) => {
    return fireBase.firestore.collection(TASKS_COLL)
        .doc(projectId)
        .collection(OUTPUT_TAKS_COLL)
        .doc(taskId)
}
