import {SvgIcon} from "@material-ui/core";

function NextHorizontal({className, color}) {
    return (
        <SvgIcon className={className} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path
                    d="M10.9092 13.6202L9.73099 12.4483C9.5128 12.2312 9.20735 12.101 8.85826 12.101H1.04735C0.829171 12.101 0.654626 12.1878 0.48008 12.318C0.305535 12.4917 0.261899 12.6653 0.261899 12.8823V15.3565C0.261899 15.5735 0.349171 15.7472 0.48008 15.9208C0.654626 16.0944 0.829171 16.1812 1.04735 16.1812H8.85826C9.16371 16.1812 9.46917 16.051 9.73099 15.834L10.9092 14.662C11.171 14.2713 11.171 13.8807 10.9092 13.6202Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M21.251 13.5769L20.0728 12.4049C19.8546 12.1878 19.5492 12.0576 19.2001 12.0576H10.211C10.2546 12.0576 10.2546 12.101 10.2983 12.1444L11.4764 13.3164C11.9128 13.7505 11.9128 14.4016 11.4764 14.8356L10.2983 16.0076C10.2983 16.0076 10.2546 16.051 10.211 16.0944H19.2001C19.5055 16.0944 19.811 15.9642 20.0728 15.7472L21.251 14.5752C21.5128 14.2714 21.5128 13.8807 21.251 13.5769Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M2.26916 21.39C3.40184 21.39 4.32007 20.4766 4.32007 19.3499C4.32007 18.2231 3.40184 17.3098 2.26916 17.3098C1.13647 17.3098 0.21825 18.2231 0.21825 19.3499C0.21825 20.4766 1.13647 21.39 2.26916 21.39Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M9.20732 18.3515L4.97459 18.3949C5.1055 18.6988 5.19278 19.0894 5.19278 19.4367C5.19278 19.7839 5.1055 20.1746 5.01823 20.4784L9.25096 20.435C9.81823 20.435 10.2982 19.9575 10.2982 19.3933C10.2982 18.829 9.81823 18.3515 9.20732 18.3515Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M13.3964 21.39C14.5291 21.39 15.4473 20.4766 15.4473 19.3499C15.4473 18.2231 14.5291 17.3098 13.3964 17.3098C12.2637 17.3098 11.3455 18.2231 11.3455 19.3499C11.3455 20.4766 12.2637 21.39 13.3964 21.39Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M20.3346 18.3515L16.1455 18.3949C16.2764 18.6988 16.3637 19.0894 16.3637 19.4367C16.3637 19.7839 16.2764 20.1746 16.1891 20.4784L20.4219 20.435C20.9891 20.435 21.4691 19.9575 21.4691 19.3933C21.3819 18.829 20.9019 18.3515 20.3346 18.3515Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M1.44011 10.5931C0.829197 10.5931 0.349197 10.1156 0.349197 9.5079V2.56283C0.349197 1.95513 0.829197 1.47766 1.44011 1.47766C2.05101 1.47766 2.53101 1.95513 2.53101 2.56283V9.5079C2.53101 10.1156 2.05101 10.5931 1.44011 10.5931Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M4.5819 10.5931C3.97099 10.5931 3.49099 10.1156 3.49099 9.5079V2.56283C3.49099 1.95513 3.97099 1.47766 4.5819 1.47766C5.19281 1.47766 5.67281 1.95513 5.67281 2.56283V9.5079C5.67281 10.1156 5.19281 10.5931 4.5819 10.5931Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M7.72369 10.5931C7.11278 10.5931 6.63278 10.1156 6.63278 9.5079V2.56283C6.63278 1.95513 7.11278 1.47766 7.72369 1.47766C8.3346 1.47766 8.8146 1.95513 8.8146 2.56283V9.5079C8.77097 10.1156 8.29097 10.5931 7.72369 10.5931Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M10.8655 10.5931C10.2546 10.5931 9.77457 10.1156 9.77457 9.5079V2.56283C9.77457 1.95513 10.2546 1.47766 10.8655 1.47766C11.4764 1.47766 11.9564 1.95513 11.9564 2.56283V9.5079C11.9128 10.1156 11.4328 10.5931 10.8655 10.5931Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M14.0073 10.5931C13.3964 10.5931 12.9164 10.1156 12.9164 9.5079V2.56283C12.9164 1.95513 13.3964 1.47766 14.0073 1.47766C14.6183 1.47766 15.0983 1.95513 15.0983 2.56283V9.5079C15.0546 10.1156 14.5746 10.5931 14.0073 10.5931Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M17.1491 10.5931C16.5382 10.5931 16.0582 10.1156 16.0582 9.5079V2.56283C16.0582 1.95513 16.5382 1.47766 17.1491 1.47766C17.76 1.47766 18.24 1.95513 18.24 2.56283V9.5079C18.1964 10.1156 17.7164 10.5931 17.1491 10.5931Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M20.2473 10.5931C19.6364 10.5931 19.1564 10.1156 19.1564 9.5079V2.56283C19.1564 1.95513 19.6364 1.47766 20.2473 1.47766C20.8582 1.47766 21.3382 1.95513 21.3382 2.56283V9.5079C21.3382 10.1156 20.8582 10.5931 20.2473 10.5931Z"
                    fill={color ?? "#707070"}/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="21.8182" height="22.9091" fill="white" transform="translate(7.62939e-05 0.000183105)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
};

export default NextHorizontal;
