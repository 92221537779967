import Cookies from 'js-cookie'
import _ from 'lodash'

export const GENIAM_ACCESS_TOKEN_KEY = process.env.REACT_APP_ENV === 'production' ? 'ge_production_accessToken' : ('stg' ? 'ge_stg_accessToken' : 'geniam_accessToken')
export const GENIAM_REFRESH_TOKEN_KEY = process.env.REACT_APP_ENV === 'production' ? 'ge_production_refreshToken' : ('stg' ? 'ge_stg_refreshToken' : 'geniam_refreshToken')
export const GENIAM_USER_INFO_KEY = process.env.REACT_APP_ENV === 'production' ? 'ge_production_user' : ('stg' ? 'ge_stg_user' : 'geniam_user')

export default () => {
    const geniam_accessToken = localStorage.getItem(GENIAM_ACCESS_TOKEN_KEY)
    const geniam_refreshToken = Cookies.get(GENIAM_REFRESH_TOKEN_KEY) || localStorage.getItem(GENIAM_REFRESH_TOKEN_KEY)
    const geniam_user = JSON.parse(localStorage.getItem(GENIAM_USER_INFO_KEY))
    return {geniam_accessToken, geniam_refreshToken, geniam_user}
}

export const isStrIncludes = (str, includes) => {
    if (!_.isString(str) || _.isEmpty(str)) return false
    if (!_.isString(includes) || _.isEmpty(includes)) return false
    return str.toLowerCase().includes(includes.toLowerCase())
}
export const isRefreshUrl = (str) => {
    return isStrIncludes(str, '/refresh')
}
