import {SvgIcon} from "@material-ui/core";

function AppDoubleNext({className, color}) {
    return (
        <SvgIcon className={className} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path
                    d="M11.6923 17.5974L10.3981 16.3033C10.1303 16.0355 9.81793 15.9016 9.50554 15.9016H1.47273C1.24959 15.9016 1.02646 15.9909 0.892577 16.1694C0.71407 16.3479 0.624817 16.571 0.624817 16.7495V19.3825C0.624817 19.6056 0.71407 19.8288 0.892577 19.9626C1.07108 20.1411 1.29422 20.2304 1.47273 20.2304H9.50554C9.81793 20.2304 10.1749 20.0965 10.3981 19.8288L11.6923 18.5346C12.0046 18.3115 12.0046 17.8652 11.6923 17.5974Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M21.9118 17.5974L20.6176 16.3033C20.3498 16.0355 20.0375 15.9016 19.7251 15.9016H11.0229C11.0675 15.9462 11.0675 15.9462 11.1121 15.9462L12.4063 17.2404C12.8526 17.6867 12.8526 18.4007 12.4063 18.847L11.1121 20.1411C11.0675 20.1858 11.0675 20.1858 11.0229 20.1858H19.7697C20.0821 20.1858 20.4391 20.0519 20.6622 19.7841L21.9564 18.49C22.1795 18.3115 22.1795 17.8652 21.9118 17.5974Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M2.27601 5.99446C3.18793 5.99446 3.9272 5.25519 3.9272 4.34327C3.9272 3.43134 3.18793 2.69208 2.27601 2.69208C1.36408 2.69208 0.624817 3.43134 0.624817 4.34327C0.624817 5.25519 1.36408 5.99446 2.27601 5.99446Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M6.78333 5.99446C7.69525 5.99446 8.43452 5.25519 8.43452 4.34327C8.43452 3.43134 7.69525 2.69208 6.78333 2.69208C5.8714 2.69208 5.13214 3.43134 5.13214 4.34327C5.13214 5.25519 5.8714 5.99446 6.78333 5.99446Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M11.2906 5.99446C12.2025 5.99446 12.9418 5.25519 12.9418 4.34327C12.9418 3.43134 12.2025 2.69208 11.2906 2.69208C10.3787 2.69208 9.63941 3.43134 9.63941 4.34327C9.63941 5.25519 10.3787 5.99446 11.2906 5.99446Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M15.7979 5.99446C16.7098 5.99446 17.4491 5.25519 17.4491 4.34327C17.4491 3.43134 16.7098 2.69208 15.7979 2.69208C14.886 2.69208 14.1467 3.43134 14.1467 4.34327C14.1467 5.25519 14.886 5.99446 15.7979 5.99446Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M20.3498 5.99446C21.2618 5.99446 22.001 5.25519 22.001 4.34327C22.001 3.43134 21.2618 2.69208 20.3498 2.69208C19.4379 2.69208 18.6986 3.43134 18.6986 4.34327C18.6986 5.25519 19.4379 5.99446 20.3498 5.99446Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M2.27601 10.0109C3.18793 10.0109 3.9272 9.27161 3.9272 8.35969C3.9272 7.44776 3.18793 6.7085 2.27601 6.7085C1.36408 6.7085 0.624817 7.44776 0.624817 8.35969C0.624817 9.27161 1.36408 10.0109 2.27601 10.0109Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M6.78333 10.0109C7.69525 10.0109 8.43452 9.27161 8.43452 8.35969C8.43452 7.44776 7.69525 6.7085 6.78333 6.7085C5.8714 6.7085 5.13214 7.44776 5.13214 8.35969C5.13214 9.27161 5.8714 10.0109 6.78333 10.0109Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M11.2906 10.0109C12.2025 10.0109 12.9418 9.27161 12.9418 8.35969C12.9418 7.44776 12.2025 6.7085 11.2906 6.7085C10.3787 6.7085 9.63941 7.44776 9.63941 8.35969C9.63941 9.27161 10.3787 10.0109 11.2906 10.0109Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M15.7979 10.0109C16.7098 10.0109 17.4491 9.27161 17.4491 8.35969C17.4491 7.44776 16.7098 6.7085 15.7979 6.7085C14.886 6.7085 14.1467 7.44776 14.1467 8.35969C14.1467 9.27161 14.886 10.0109 15.7979 10.0109Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M20.3498 10.0109C21.2618 10.0109 22.001 9.27161 22.001 8.35969C22.001 7.44776 21.2618 6.7085 20.3498 6.7085C19.4379 6.7085 18.6986 7.44776 18.6986 8.35969C18.6986 9.27161 19.4379 10.0109 20.3498 10.0109Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M2.27601 14.2504C3.18793 14.2504 3.9272 13.5111 3.9272 12.5992C3.9272 11.6873 3.18793 10.948 2.27601 10.948C1.36408 10.948 0.624817 11.6873 0.624817 12.5992C0.624817 13.5111 1.36408 14.2504 2.27601 14.2504Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M6.78333 14.2504C7.69525 14.2504 8.43452 13.5111 8.43452 12.5992C8.43452 11.6873 7.69525 10.948 6.78333 10.948C5.8714 10.948 5.13214 11.6873 5.13214 12.5992C5.13214 13.5111 5.8714 14.2504 6.78333 14.2504Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M11.2906 14.2504C12.2025 14.2504 12.9418 13.5111 12.9418 12.5992C12.9418 11.6873 12.2025 10.948 11.2906 10.948C10.3787 10.948 9.63941 11.6873 9.63941 12.5992C9.63941 13.5111 10.3787 14.2504 11.2906 14.2504Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M15.7979 14.2504C16.7098 14.2504 17.4491 13.5111 17.4491 12.5992C17.4491 11.6873 16.7098 10.948 15.7979 10.948C14.886 10.948 14.1467 11.6873 14.1467 12.5992C14.1467 13.5111 14.886 14.2504 15.7979 14.2504Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M20.3498 14.2504C21.2618 14.2504 22.001 13.5111 22.001 12.5992C22.001 11.6873 21.2618 10.948 20.3498 10.948C19.4379 10.948 18.6986 11.6873 18.6986 12.5992C18.6986 13.5111 19.4379 14.2504 20.3498 14.2504Z"
                    fill={color ?? "#707070"}/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="22.3134" height="22.3134" fill="white" transform="translate(6.10352e-05 0.460754)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default AppDoubleNext;
