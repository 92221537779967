import fireBase from "../../config/firebaseConfig";
import {OUTPUT_COLL, TASKS_COLL} from "../constants";


export const outputsCollRef = (projectId) => {
    return fireBase.firestore.collection(TASKS_COLL)
        .doc(projectId)
        .collection(OUTPUT_COLL)
}

export const outputsDocRef = (projectId, outputId) => {
    return fireBase.firestore.collection(TASKS_COLL)
        .doc(projectId)
        .collection(OUTPUT_COLL)
        .doc(outputId)
}
