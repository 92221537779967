import React, {useState} from 'react';
import {makeStyles,} from "@material-ui/styles";
import projectColor from '../../common/colorProject'
import {Popover} from '@material-ui/core'
import {CirclePicker} from 'react-color'

function SelectColor({color, setColor}) {
    const classes = useStyles()

    const [openSelect, setOpenSelect] = useState(null)
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                COLOR
                <div className={classes.colorBox}
                     onClick={(e) => setOpenSelect(e.currentTarget)}
                >
                    <div className={classes.color} style={{background: color}}/>
                </div>
            </div>
            <Popover open={Boolean(openSelect)}
                     onClose={() => setOpenSelect(null)}
                     anchorEl={openSelect}
                     anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'left',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                     }}
            >
                <div className={classes.popoverContent}>
                    <CirclePicker
                        circleSize={17}
                        circleSpacing={10}
                        colors={projectColor}
                        corlor={color}
                        onChangeComplete={color => {
                            setColor(color.hex)
                            setOpenSelect(null)
                        }}
                    />
                </div>
            </Popover>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        padding: "0 5px"
    },
    content: {
        backgroundColor: "#f1f1f1",
        padding: "5px 10px",
        fontWeight: 700,
        borderRadius: 20,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
    },
    colorBox: {
        width: 25,
        height: 25,
        borderRadius: "50%",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    color: {
        width: 17,
        height: 17,
        borderRadius: "50%",
    },
    popoverContent: {
        padding: 20
    }
}))

export default SelectColor;
