import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axiosConfig from './config/axios';
import {createBrowserHistory} from 'history';
import {BrowserRouter} from 'react-router-dom';
import fireBase from './config/firebaseConfig';
import {TaskUserProvider} from './context/TaskUserContext';
import {TaskProjectsProvider} from "./context/TaskProjectsContext";

axiosConfig();
fireBase.init();

require('./config/GlobalState');

ReactDOM.render(
    <BrowserRouter history={createBrowserHistory()}>
        <TaskUserProvider>
            <TaskProjectsProvider>
                <App/>
            </TaskProjectsProvider>
        </TaskUserProvider>
    </BrowserRouter>
    , document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
