import {v4} from 'uuid'
import {projectDocRef} from "../../common/firebaseRef/project";
import {getGlobal} from 'reactn'
import fireBase from "../../config/firebaseConfig";
import {phaseDocRef} from "../../common/firebaseRef/phases";
import moment from "moment";

export default async (data) => {
    const {user} = getGlobal()
    const uid = v4()
    const batch = fireBase.firestore.batch()
    const ref = projectDocRef(uid)
    const projectData = {...data, id: uid, userId: user.user_id, isDeleted: false}
    batch.set(ref, projectData)
    // default 1 phase
    const phaseId = v4()
    const phaseRef = phaseDocRef(uid, phaseId)
    const phaseInfo = {
        name: "Phase 1",
        createdAt: moment().format(),
        updatedAt: moment().format(),
        id: phaseId,
        projectId: uid,
        isDeleted: false,
        start: data.start || null,
        end: data.end || null
    }
    batch.set(phaseRef, phaseInfo)
    await batch.commit()
    return projectData

}
