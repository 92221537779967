import {addCallback, setGlobal} from 'reactn'
import {isNodeEnvDevelopment} from "./env";

if (isNodeEnvDevelopment) addCallback(global => {
    console.log(global)
})

setGlobal({
    // DATA
    events: [],
    projects: [],
    user: {},
    taskUser: {},
    identities: [],
    openAddCategory: null,
    openAddPhase: null,
    openAddOutput: null,
    openAddTask: null,
})
