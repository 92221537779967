import {List, NotFound, ProjectDashboard, Setting} from './splittings';
import {HOME, LIST_TASK, NOT_FOUND, PROJECT_DARTBOARD, SETTING} from "./router";

export default [
    {route: HOME, page: ProjectDashboard, exact: true},
    {route: PROJECT_DARTBOARD, page: ProjectDashboard},
    {route: LIST_TASK, page: List},
    // {route: CATEGORY_DARTBOARD, page: CategoryDashboard},
    {route: SETTING, page: Setting},
    {route: NOT_FOUND, page: NotFound},
]
