import React, {useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {Button, Grid, TextField} from "@material-ui/core";
import DialogContainer from "./DialogContainer";
import SelectColor from "../Custom/SelectColor";
import moment from "moment";
import {FULLDAY_FORMAT} from "../../common/dateFormat";
import SelectDate from "../Custom/SelectDate";
import API from '../../api/index'
import SelectIcon from "../Custom/SelectIcon";

function AddProject({onDone}) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [name, setName] = useState("")
    const [color, setColor] = useState("#fe7f0a")
    const [start, setStart] = useState(moment().format(FULLDAY_FORMAT))
    const [end, setEnd] = useState(null)
    const [loading, setLoading] = useState(false)
    const [icon, setIcon] = useState('group')
    const handleAdd = async () => {
        try {
            setLoading(true)
            const projectData = {
                name,
                color,
                icon,
                createdAt: moment().format(),
                updatedAt: moment().format(),
                start,
                end
            }
            const result = await API.addproject(projectData)
            console.log(result);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            if (typeof onDone === "function")
                onDone()
        }
    }
    return (
        <div className={classes.addBtnContainer}>
            <Button fullWidth={true} className={classes.addBtn}
                    onClick={() => setOpen(true)}
            >
                <img alt={'add project'} src={"imgs/addProject.svg"}/>
                <span className={classes.span}>
                        NEW PROJECT
                            </span>
            </Button>
            <DialogContainer
                open={open}
                onClose={() => setOpen(false)}
                onOk={handleAdd}
                disabled={!name || loading}
            >
                <Grid container>
                    <Grid item xs={7}>
                        <TextField
                            fullWidth={true}
                            // label={"Project Name"}
                            value={name}
                            onChange={e => setName(e.target.value)}
                            placeholder={"Project Name"}
                            autoFocus={true}

                        />
                    </Grid>
                    <Grid item xs={5} className={classes.select}>
                        <SelectIcon icon={icon} setIcon={setIcon}/>
                        <SelectColor color={color} setColor={setColor}/>
                    </Grid>

                </Grid>
                <div className={classes.timeContainer}>
                    <img alt={'time'} src={'imgs/time.svg'} className={classes.timeImg}/>
                    <SelectDate date={start} setDate={(date) => {
                        if (end && moment(date).isAfter(end)) {
                            setStart(date)
                            setEnd(null)
                            return
                        }
                        setStart(date)
                    }}/>
                    <img alt={'time'} src={'imgs/timeTo.svg'}/>
                    <SelectDate date={end} setDate={(date) => {
                        if (moment(date).isBefore(start))
                            return;
                        setEnd(date)
                    }}/>
                </div>

            </DialogContainer>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    addBtnContainer: {
        width: 150,
        margin: "10px auto",
    },
    addBtn: {
        fontSize: 12,
        fontWeight: 700,
        color: "#1790FF",
        margin: 0,
        padding: 0,
        backgroundColor: "#f1f1f1",
        justifyContent: "inherit"
    },
    span: {
        width: "100%"
    },
    timeContainer: {
        display: "flex",
        width: "100%",
        marginTop: 10,
        marginBottom: 10
    },
    timeImg: {
        width: 28
    },
    select: {
        display: "flex",
        alignItems: "center"
    }

}))

export default AddProject;
