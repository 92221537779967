import {v4} from 'uuid'
import {getGlobal} from 'reactn'
import fireBase from "../../config/firebaseConfig";
import {taskDocRef} from "../../common/firebaseRef/tasks";

export default async (projectId, data) => {
    const {user} = getGlobal()
    const uid = v4()
    const batch = fireBase.firestore.batch()
    const ref = taskDocRef(projectId, uid)
    const phaseData = {...data, id: uid, userId: user.user_id, isDeleted: false}
    batch.set(ref, phaseData)
    await batch.commit()
    return phaseData

}
