export let LOGIN = process.env.REACT_APP_LOGIN
export let API_PREFIX = process.env.REACT_APP_API_PREFIX
export let API_TASK = process.env.REACT_APP_API_TASK
export let API_CART = process.env.REACT_APP_CART_URL
// export let API_CAL = process.env.REACT_APP_API_CAL
export const USER_INFO = API_PREFIX + "/getuserdata"
export const LOGOUT_URL = API_PREFIX + "/logout"
export const CHECK_USER = API_TASK + "/users"
export const TASKS = API_TASK + "/tasks"
export const CATEGORIES = API_TASK + "/categories"
export const PRIORITIES = API_TASK + "/priorities"
export const PRIORITY = API_TASK + "/priority"
export const PROJECTS = API_TASK + "/projects"
export const IDENTITIES = API_TASK + "/identity"
export const INIT_TASK = API_TASK + "/tasks"
