import axios from "axios"
import Cookies from "js-cookie"
import {refreshToken} from "../actions/user"
import Token, {
    GENIAM_ACCESS_TOKEN_KEY,
    GENIAM_REFRESH_TOKEN_KEY,
    GENIAM_USER_INFO_KEY,
    isStrIncludes
} from './localTokens'
import {API_PREFIX, LOGIN} from "./apiUrl"
import {toast} from "react-toastify";

axios.defaults.baseURL = API_PREFIX

export default () => {
    //REQUEST
    axios.interceptors.request.use(
        async function (config) {
            const {geniam_accessToken, geniam_refreshToken} = Token()
            if (isStrIncludes(config.url, '/refresh')) {
                return config
            }
            if (isStrIncludes(config.url, '/logout')) {
                config.headers['Authorization'] = `Bearer ${geniam_accessToken}`
                localStorage.removeItem(GENIAM_ACCESS_TOKEN_KEY)
                Cookies.remove(GENIAM_REFRESH_TOKEN_KEY)
                localStorage.removeItem(GENIAM_USER_INFO_KEY)
                return config
            }
            if (geniam_accessToken) {
                config.headers['Authorization'] = `Bearer ${geniam_accessToken}`
                return config
            } else if (geniam_refreshToken) {
                const data = await refreshToken()
                if (data) {
                    config.headers['Authorization'] = `Bearer ${data.accessToken}`
                    return config
                }
            } else {
                window.location.assign(LOGIN + '/login?redirect_url=' + window.location.href)
                return null
            }
        },
        error => {
            if (error && error.request) {
                toast.error(error.message)
            }
            return Promise.reject(error)
        })

    //RESPONSE
    axios.interceptors.response.use(
        function (response) {
            // if(response.data.message){
            //     toast.success(response.data.message, {
            //         position: toast.POSITION.BOTTOM_CENTER,
            //         autoClose: 2000,
            //         hideProgressBar: true
            //     })
            // }
            return response;
        },
        async function (error) {
            const originalRequest = error.config
            // if(error.response.data.message){
            //     toast.error(error.response.data.message, {
            //         position: toast.POSITION.BOTTOM_CENTER,
            //         autoClose: 2000,
            //         hideProgressBar: true
            //     })
            // }

            //if /refresh error then reject error
            if (isStrIncludes(originalRequest.url, '/refresh')) {
                return Promise.reject(error);
            }

            //else other error
            if (error.response.status === 400 && ( error.response.data.error_code === 'NotAuthorizedException' || error.response.data.message === 'Invalid Access Token')) {
                if(error.response.data.error_message === 'Invalid Refresh Token' || error.response.data.error_message === 'Refresh Token has been revoked'){
                    localStorage.removeItem(GENIAM_ACCESS_TOKEN_KEY)
                    Cookies.remove(GENIAM_REFRESH_TOKEN_KEY)
                    localStorage.removeItem(GENIAM_USER_INFO_KEY)
                    window.location.assign(LOGIN + '/login?redirect_url=' + window.location.href)
                }
                const data = await refreshToken()
                if (data) {
                    originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;
                    return axios(originalRequest)
                } else {
                    return Promise.reject(error)
                }
            }

            return Promise.reject(error);
        }
    )
}
