import React from 'react';
import {Divider, Drawer} from "@material-ui/core"
import {makeStyles} from "@material-ui/styles";
import {useGlobal} from 'reactn'
import AddProject from "../Dialog/AddProject";
import ProjectList from "./ProjectList";

function ProjectsDrawer({open, setOpen}) {
    const classes = useStyles()
    const [projects] = useGlobal('projects')
    return (
        <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
            <div className={classes.content}>
                {/*add btn*/}
                <AddProject
                    onDone={() => setOpen(false)}
                />
                <Divider/>
                {/*list projects*/}
                <ProjectList/>
                <Divider/>
            </div>

        </Drawer>
    );
}

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        width: 200,
        marginTop: 70
    },

}))

export default ProjectsDrawer;
