import {SvgIcon} from "@material-ui/core";

function Logo({className}) {
    return (
        <SvgIcon className={className} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 27.7943C21.6184 27.7943 27.7944 21.6184 27.7944 13.9999C27.7944 6.38152 21.6184 0.205566 14 0.205566C6.38158 0.205566 0.205627 6.38152 0.205627 13.9999C0.205627 21.6184 6.38158 27.7943 14 27.7943Z"
                fill="white"/>
            <path
                d="M14.0431 0.824329C9.70627 0.764329 5.45189 3.0612 3.04252 6.6537C0.981893 9.65558 0.280643 13.5293 1.11127 17.0693C1.78627 20.0206 3.52252 22.7112 5.92439 24.5525C8.22502 26.3318 11.1238 27.3593 14.0394 27.3106C14.0225 26.2475 14.0525 25.1843 14.0169 24.1212C16.7844 24.1193 19.5294 22.9343 21.3988 20.8868C23.2944 18.8618 24.2638 16.0306 24.0406 13.2668C21.2806 13.2631 18.5225 13.2875 15.7644 13.2762C15.7569 14.5437 15.7681 15.8131 15.7569 17.0825C17.0131 17.0768 18.2675 17.0862 19.5219 17.075C18.4381 19.0306 16.2744 20.3693 14.0206 20.3131C14.0713 16.1468 14.0394 11.9768 14.0375 7.81058C16.6588 7.7862 19.0681 9.57683 19.97 12.0087C21.2675 11.9862 22.5631 11.9862 23.8606 12.0031C23.3938 9.83933 22.2106 7.8312 20.5119 6.40808C18.7569 4.88183 16.46 4.11308 14.1594 3.98183H14.1369V3.98558H14.0338V6.26558L14.0375 7.7937C11.93 7.85558 9.79064 8.96933 8.70127 10.7937C7.34189 12.9275 7.49377 15.8787 9.05752 17.8662C10.2031 19.4 12.1213 20.2662 14.0206 20.3262C14.0188 21.5993 14.0394 22.7675 14.0169 24.1212C11.6525 24.1606 9.26189 23.2231 7.47689 21.6837C5.27377 19.8087 3.95564 16.9437 3.98377 14.0506C3.96689 11.1031 5.35064 8.1987 7.62877 6.33495C9.36689 4.9062 11.7688 3.9162 14.0338 3.98558C14.0375 3.97433 14.0394 3.96308 14.0431 3.9537"
                fill="#1790FF"/>
        </SvgIcon>
    )
}

export default Logo;
