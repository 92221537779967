import {SvgIcon} from "@material-ui/core";

function Tree({className, color}) {
    return (
        <SvgIcon className={className} viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.490326" y="0.00952148" width="7.59617" height="7.59617" rx="2" fill={color ?? "#707070"}/>
            <rect x="13.4038" y="0.00952148" width="7.59617" height="7.59617" rx="2" fill={color ?? "#707070"}/>
            <rect x="6.5672" y="11.4038" width="7.59617" height="7.59617" rx="2" fill={color ?? "#707070"}/>
            <line x1="7.32687" y1="3.74005" x2="14.923" y2="3.74005" stroke="#707070" stroke-width="2"/>
            <line x1="6.01533" y1="6.6996" x2="9.26483" y2="13.5657" stroke="#707070" stroke-width="2"/>
        </SvgIcon>
    )
}

export default Tree;
