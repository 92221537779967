import React, {createContext, useContext, useEffect} from 'react';
import {setGlobal, useGlobal} from 'reactn';
import fireBase from '../config/firebaseConfig';
import {toast} from 'react-toastify';
import {omitByDeep} from '../common/exLodash';
import _ from 'lodash';
import Cookies from 'js-cookie';
import {GENIAM_REFRESH_TOKEN_KEY} from '../config/localTokens';
import qs from 'query-string';
import {calendar, getUserData, signInFirebase} from '../actions/user';
import {useHistory} from 'react-router-dom';

export const TaskUserContext = createContext({});

export const useTaskUserContext = () => {
    return useContext(TaskUserContext);
};

export const TaskUserProvider = ({children}) => {
    const taskUser = useTaskUser();
    const query = qs.parse(window.location.search);
    const path = window.location.pathname;
    const history = useHistory();

    useEffect(() => {
        const {
            token,
            refreshToken,
        } = query;

        if (token || refreshToken) {
            const opts = {expires: 1826};
            if (process.env.NODE_ENV ===
                'production') opts.domain = '.geniam.com';
            localStorage.setItem('accessToken', token);
            localStorage.setItem('refreshToken', refreshToken);
            Cookies.set(GENIAM_REFRESH_TOKEN_KEY, refreshToken, opts);
            delete query.token;
            delete query.refreshToken;
            history.push(`${path}?${qs.stringify(query)}`);
        }
        getUserData().then(data => {
                if (!data)
                    setGlobal({langDefault: 'ja-jp'});
            },
        ).catch(e => {
            console.log('error TaskUserProvider useEffect');
        });
        fireBase.auth.onAuthStateChanged(user => {
            if (!user) {
                signInFirebase();
            }
        });
        // eslint-disable-next-line
    }, []);
    // console.log(taskUser)

    return (
        <TaskUserContext.Provider value={taskUser}>
            {children}
        </TaskUserContext.Provider>
    );
};

export const useTaskUser = () => {
    const [taskUser = {}, setTaskUser] = useGlobal('taskUser');
    const [user] = useGlobal('user');

    useEffect(() => {
        if (!user || !user.user_id) return;
        // console.log('sub useTaskUser', user.user_id)
        const unsub = fireBase.firestore.doc(calendar + `/${user.user_id}`).onSnapshot(
            snapshot => {
                if (!snapshot.exists) return;
                const data = {id: snapshot.id, ...snapshot.data()};
                let identities = (data?.identitySettings || []).map(d => ({...d, uuid: d.id}));
                setTaskUser(data);
                setGlobal({
                    identities: identities || []
                })
            },
            error => {
                alert(error.message);
            },
        );

        return () => {
            unsub && unsub();
        };
        // eslint-disable-next-line
    }, [user.user_id]);

    const mergeTaskUser = (data, {onOk, onFailed}) => {
        if (!user || !user.user_id) return;
        if (!_.isPlainObject(data)) return;
        data = omitByDeep(data, _.isUndefined);

        fireBase.firestore.doc(calendar + `/${user.user_id}`).set(data, {merge: true}).then((val) => {
            toast.success('updated');
            if (_.isFunction(onOk)) onOk(val);
        }).catch(e => {
            toast.error(e.message);
            if (_.isFunction(onFailed)) onFailed(e);
        });
    };

    return {taskUser, mergeTaskUser};
};
