import fireBase from "../../config/firebaseConfig";
import {PHASES_COLL, TASKS_COLL} from "../../common/constants";

export const getPhasesSnap = (projectId, callback) => {
    return fireBase.firestore.collection(TASKS_COLL)
        .doc(projectId)
        .collection(PHASES_COLL)
        .onSnapshot(querySnapshot => {
                if (callback) {
                    callback(null, querySnapshot);
                }
            },
            error => {
                if (callback) {
                    callback(error, null);
                }
            },
        );

};

export const getPhases = async (projectId, callback) => {
    try {
        const snaps = await fireBase.firestore.collection(TASKS_COLL)
            .doc(projectId)
            .collection(PHASES_COLL)
            .get()
        if (callback) {
            callback(null, snaps);
        }
    } catch
        (e) {
        if (callback) {
            callback(e, null);
        }
    }
}
