import _ from 'lodash'

export const omitByDeep = (obj, shouldOmit) => {
    obj = _.clone(obj);
    _.forEach(obj, (value, key) => {
        if (shouldOmit(value, key)) {
            delete obj[key];
        }
        if (_.isPlainObject(value)) {
            obj[key] = omitByDeep(value, shouldOmit);
        }
    })
    return obj;
}
