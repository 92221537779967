import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import {IS_PRODUCTION} from '../actions/user';

const firebaseConfig = {
    apiKey: "AIzaSyBROAxpDRqRIqZvrAGE7UK7uvaIUcxooS0",
    authDomain: "geniam-c8d4c.firebaseapp.com",
    databaseURL: "https://geniam-c8d4c.firebaseio.com",
    projectId: "geniam-c8d4c",
    storageBucket: "geniam-c8d4c.appspot.com",
    messagingSenderId: "92022981782",
    appId: "1:92022981782:web:17a2ab710f867e60b56eb7",
    measurementId: "G-ZZVDYP9EN4"
};

const fireBase = {

    firestore: null,
    auth: null,
    storage: null,
    fire: null,
    isInit: false,

    init: () => {
        if (fireBase.isInit) return;
        firebase.initializeApp(firebaseConfig);

        fireBase.firestore = firebase.firestore();
        fireBase.auth = firebase.auth();
        fireBase.storage = firebase.storage();
        fireBase.fire = firebase;

        if (IS_PRODUCTION) {
            //enable local cache
            firebase.firestore().settings({
                cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
            });
            firebase.firestore().enablePersistence({synchronizeTabs: true})
                .then(() => {
                    console.log('firestore cached')
                })
                .catch(function (err) {
                    // alert(err.message)
                    console.error(err)
                });
            // Subsequent queries will use persistence, if it was enabled successfully
        }

        fireBase.isInit = true;
    }
};

export default fireBase;
