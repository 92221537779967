import {SvgIcon} from "@material-ui/core";

function DoubleLabelNext({className, color}) {
    return (
        <SvgIcon className={className} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path
                    d="M13.6663 11.8712L9.72606 7.93092C8.97791 7.18277 7.98037 6.78375 6.93295 6.78375H2.4939C1.8455 6.78375 1.14723 7.03314 0.698334 7.53191C0.199565 8.03068 -0.0498199 8.67908 -0.0498199 9.32748V17.5073C-0.0498199 18.1557 0.199565 18.854 0.698334 19.3029C1.1971 19.8016 1.8455 20.051 2.4939 20.051H6.98283C8.03024 20.051 9.02778 19.652 9.77594 18.9039L13.7162 14.9636C14.5641 14.1157 14.5641 12.7191 13.6663 11.8712Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M24.2901 11.8213L20.3499 7.88106C19.6017 7.1329 18.6042 6.73389 17.5567 6.73389H11.4219C11.5216 6.78376 11.5715 6.88352 11.6713 6.93339L15.6115 10.8737C17.0081 12.2702 17.0081 14.4648 15.6115 15.8614L11.6713 19.8016C11.6214 19.8515 11.5216 19.9513 11.4219 20.0012H17.5567C18.6042 20.0012 19.6017 19.6021 20.3499 18.854L24.2901 14.9137C25.138 14.0658 25.138 12.6692 24.2901 11.8213Z"
                    fill={color ?? "#707070"}/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24.9385" height="24.9385" fill="white" transform="translate(6.10352e-05 0.648926)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
};

export default DoubleLabelNext;
