import React, {useState} from 'react';
import {makeStyles,} from "@material-ui/styles";
import {Popover} from '@material-ui/core'
import {iconList} from "../../common/iconList";

function SelectIcon({icon = 'group', setIcon}) {
    const classes = useStyles()

    const [openSelect, setOpenSelect] = useState(null)
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                ICON
                <div className={classes.iconBox}
                     onClick={(e) => setOpenSelect(e.currentTarget)}
                >
                    <span className="material-icons md-18">
                        {icon}
                            </span>
                </div>
            </div>
            <Popover open={Boolean(openSelect)}
                     onClose={() => setOpenSelect(null)}
                     anchorEl={openSelect}
                     anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'left',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                     }}
            >
                <div className={classes.popoverContent}>
                    <div className={classes.listIcon}>
                        {
                            iconList.map((item, index) => {
                                return (
                                    <div key={index} className={classes.iconItem}
                                         onClick={() => {
                                             setIcon(item)
                                             setOpenSelect(null)
                                         }}
                                    >
                                 <span className="material-icons md-24">
                        {item}
                            </span>
                                    </div>)
                            })
                        }
                    </div>
                </div>
            </Popover>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        padding: "0 5px"
    },
    content: {
        backgroundColor: "#f1f1f1",
        padding: "5px 10px",
        fontWeight: 700,
        borderRadius: 20,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
    },
    iconBox: {
        width: 25,
        height: 25,
        borderRadius: "50%",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    icon: {
        width: 17,
        height: 17,
        borderRadius: "50%",
    },
    popoverContent: {
        padding: 20,

    },
    listIcon: {
        width: 200,
        display: "flex",
        flexWrap: "wrap",
        height: 300
    },
    iconItem: {
        margin: 3,
        cursor: "pointer"
    }
}))

export default SelectIcon;
