import * as React from 'react';
import {SvgIcon} from "@material-ui/core";


function List({className, color}) {
    return (
        <SvgIcon className={className} viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.69599 6.95201C5.00822 6.95201 6.07199 5.88824 6.07199 4.57601C6.07199 3.26378 5.00822 2.20001 3.69599 2.20001C2.38376 2.20001 1.31999 3.26378 1.31999 4.57601C1.31999 5.88824 2.38376 6.95201 3.69599 6.95201Z"
                fill={color ?? "#707070"}/>
            <path
                d="M24.5 3.388H6.86401C6.99601 3.74 7.08401 4.18 7.08401 4.576C7.08401 4.972 6.99601 5.412 6.86401 5.764H24.5C25.16 5.764 25.688 5.236 25.688 4.576C25.688 3.916 25.16 3.388 24.5 3.388Z"
                fill={color ?? "#707070"}/>
            <path
                d="M3.69599 12.752C5.00822 12.752 6.07199 11.6882 6.07199 10.376C6.07199 9.06377 5.00822 8 3.69599 8C2.38376 8 1.31999 9.06377 1.31999 10.376C1.31999 11.6882 2.38376 12.752 3.69599 12.752Z"
                fill={color ?? "#707070"}/>
            <path
                d="M24.5 9.18799H6.86401C6.99601 9.53999 7.08401 9.97999 7.08401 10.376C7.08401 10.772 6.99601 11.212 6.86401 11.564H24.5C25.16 11.564 25.688 11.036 25.688 10.376C25.688 9.71599 25.16 9.18799 24.5 9.18799Z"
                fill={color ?? "#707070"}/>
            <path
                d="M3.69599 18.752C5.00822 18.752 6.07199 17.6882 6.07199 16.376C6.07199 15.0638 5.00822 14 3.69599 14C2.38376 14 1.31999 15.0638 1.31999 16.376C1.31999 17.6882 2.38376 18.752 3.69599 18.752Z"
                fill={color ?? "#707070"}/>
            <path
                d="M24.5 15.188H6.86401C6.99601 15.54 7.08401 15.98 7.08401 16.376C7.08401 16.772 6.99601 17.212 6.86401 17.564H24.5C25.16 17.564 25.688 17.036 25.688 16.376C25.688 15.716 25.16 15.188 24.5 15.188Z"
                fill={color ?? "#707070"}/>
        </SvgIcon>
    );
}

export default List;
