import React, {memo, Suspense, useEffect} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuAppBar from './components/MenuAppBar';
import {getTasksData} from './actions/user';
import {useGlobal} from 'reactn';
import {TaskEventsProvider} from './context/TasksEventsContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import routes from './routes';

function App() {

    const [user] = useGlobal('user');

    useEffect(() => {
        if (user.user_id) {
            getTasksData();
        }
        // eslint-disable-next-line
    }, [user]);

    return (
        <TaskEventsProvider>
            <Suspense fallback={<SuspenseLoader/>}>
                <MenuAppBar/>
                <Switch>
                    {routes.map(({route, page, exact}) => {
                        return <Route exact={exact ?? false} path={route} component={page}/>
                    })}
                </Switch>
            </Suspense>
            <ToastContainer/>
        </TaskEventsProvider>
    );
}

const SuspenseLoader = memo(() => {
    return (<LinearProgress/>);
});

export default withRouter(App);
