import fireBase from "../../config/firebaseConfig";
import {PHASES_COLL, TASKS_COLL} from "../constants";


export const phasesCollRef = (projectId) => {
    return fireBase.firestore.collection(TASKS_COLL)
        .doc(projectId)
        .collection(PHASES_COLL)
}

export const phaseDocRef = (projectId, phaseId) => {
    return fireBase.firestore.collection(TASKS_COLL)
        .doc(projectId)
        .collection(PHASES_COLL)
        .doc(phaseId)
}
