import fireBase from "../../config/firebaseConfig";
import {getGlobal} from 'reactn'

export default async (files) => {
    if (!files || !files?.length)
        return []
    const {user} = getGlobal()
    const result = await Promise.all(Array.from(files).map(async file => {
        try {
            const timeUpload = new Date().getTime()
            const ref = fireBase.storage.ref()
                .child(`tasks/${user.user_id}/attachments/${timeUpload}_${file.name}`)
            const snap = await ref.put(file)
            const uri = await snap.ref.getDownloadURL()
            return {
                uri,
                name: file.name,
                type: file.name?.includes(".") ? file.name.split(".").pop() : ""
            }
        } catch (e) {
            return null
        }
    }))
    return result.filter(x => x)
}
