import fireBase from "../../config/firebaseConfig";
import {TASKS_COLL} from "../constants";


export const projectCollRef = () => {
    return fireBase.firestore.collection(TASKS_COLL)
}

export const projectDocRef = (projectId) => {
    return fireBase.firestore.collection(TASKS_COLL)
        .doc(projectId)
}
