import React, {useEffect, useState} from 'react'
import {AppBar, Button, IconButton, Paper, Toolbar, withStyles,} from '@material-ui/core'
import {AccountCircle, Notifications, Settings,} from '@material-ui/icons'
import {Link} from "react-router-dom"
import Help from '../../elements/Icons/Help'
import {useGlobal} from "reactn";
import Avatar from "@material-ui/core/Avatar";
import AccountPopover from "./AccountPopover";
import Tree from "../../elements/Icons/Tree";
import DoubleLabelNext from "../../elements/Icons/DoubleLabelNext";
import List from "../../elements/Icons/List";
import AppDoubleNext from "../../elements/Icons/AppDoubleNext";
import StepDoubleNext from "../../elements/Icons/StepDoubleNext";
import MenuApp from "../../elements/Icons/MenuApp";
import Logo from "../../elements/Icons/Logo";
import NextHorizontal from "../../elements/Icons/NextHorizontal";
import {CATEGORY_DARTBOARD, HOME, LIST_TASK} from "../../routes/router";
import ProjectsDrawer from "../ProjectsDrawer";

const styles = {
    root: {
        width: '100%',
        paddingBottom: 64,
    },
    menu: {
        backgroundColor: '#F1F1F1',
        // position: "relative",
        zIndex: 9200
    },
    toolbar: {
        padding: 0
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        // marginLeft: -12,
        marginRight: 20,
    },
    icons: {
        height: 29.48,
        width: 29.32,
        color: '#444444'
    },
    button: {
        width: 73.5,
        height: 42,
        borderRadius: 5,
        margin: '5px 0px 5px 5px',
        backgroundColor: '#F1F1F1',
        padding: 0,
    },
    menuBtn: {
        marginRight: 20,
        marginLeft: 10
    }
}


function MenuAppBar(props) {

    // get props
    const {classes} = props

    // inti variable
    const [user] = useGlobal('user')
    // const isMenuOpen = Boolean(anchorEl)
    // const [appListAnchorEl, setAppListAnchorEl] = useState(null)
    const [accountAnchorEl, setAccountAnchorEl] = useState(null)
    const [tabBarPath, setTabBarPath] = useState(HOME)
    const [openCheckExpDateDialog] = useGlobal('openCheckExpDateDialog')
    const [openMenu, setOpenMenu] = useState(false)

    // inti function
    // const handleClickAppList = (event) => {
    //     setAppListAnchorEl(event.currentTarget)
    // }
    //
    // const handleCloseAppList = () => {
    //     setAppListAnchorEl(null)
    // }
    const handleCloseAccount = () => {
        setAccountAnchorEl(null)
    }

    const handleClickAccount = (event) => {
        setAccountAnchorEl(event.currentTarget)
    }

    useEffect(() => {
        setTabBarPath(window.location.pathname)
    })

    useEffect(() => {
        console.log('MenuAppBar deps isEmpty')
    }, [])

    useEffect(() => {
        console.log('MenuAppBar deps user')
    }, [user])

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.menu}>
                <Toolbar className={classes.toolbar}>
                    <IconButton className={classes.menuBtn}
                                onClick={() => setOpenMenu(!openMenu)}
                    >
                        <img alt={'menu'} src={"imgs/menu.svg"}/>
                    </IconButton>
                    <Button
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Open drawer"
                        component={Link}
                        to={HOME}
                        startIcon={<Logo className={classes.icons}/>}
                    >
                        <h4 style={{margin: 0, color: '#1790FF'}}>Task</h4>
                    </Button>

                    <Paper elevation={0} style={{backgroundColor: '#FFF'}}>
                        <IconButton
                            color="primary"
                            className={classes.button}
                        >
                            <Tree className={classes.icons}/>
                        </IconButton>
                        <IconButton
                            color="primary"
                            className={classes.button}
                            component={Link}
                            to={HOME}
                        >
                            <DoubleLabelNext
                                className={classes.icons}
                                color={tabBarPath === HOME ? '#1790FF' : '#707070'}/>
                        </IconButton>
                        <IconButton
                            color="primary"
                            className={classes.button}
                            component={Link}
                            to={LIST_TASK}
                        >
                            <List
                                className={classes.icons}
                                color={tabBarPath === LIST_TASK ? '#1790FF' : '#707070'}/>
                        </IconButton>
                        <IconButton
                            color="primary"
                            className={classes.button}
                            component={Link}
                            to={CATEGORY_DARTBOARD}
                        >
                            <StepDoubleNext className={classes.icons}
                                            color={tabBarPath === CATEGORY_DARTBOARD ? '#1790FF' : '#707070'}/>
                        </IconButton>
                        <IconButton
                            color="primary"
                            className={classes.button}
                        >
                            <AppDoubleNext className={classes.icons}/>
                        </IconButton>
                        <IconButton
                            color="primary"
                            className={classes.button}
                        >
                            <NextHorizontal className={classes.icons}/>
                        </IconButton>
                        <IconButton
                            color="primary"
                            className={classes.button}
                            style={{marginRight: 5}}
                        >
                            <MenuApp className={classes.icons}/>
                        </IconButton>
                    </Paper>
                    <div className={classes.grow}/>

                    <IconButton
                        color="primary"
                    >
                        <Help className={classes.icons}/>
                    </IconButton>
                    <IconButton
                        color="primary"
                    >
                        <Notifications className={classes.icons}/>
                    </IconButton>
                    {
                        openCheckExpDateDialog === false &&
                        <IconButton
                            color="primary"
                            component={Link}
                            to={'/setting'}
                        >
                            <Settings className={classes.icons}/>
                        </IconButton>
                    }

                    {/*<div>*/}
                    {/*    <IconButton color="inherit" onClick={handleClickAppList}>*/}
                    {/*        <Apps/>*/}
                    {/*    </IconButton>*/}
                    {/*    <AppListPopover*/}
                    {/*        anchorEl={appListAnchorEl}*/}
                    {/*        handleClose={handleCloseAppList}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div>
                        <IconButton color="inherit" onClick={handleClickAccount}>
                            {user.avatar ? (
                                <Avatar
                                    src={user.avatar}
                                />
                            ) : (
                                <AccountCircle/>
                            )}
                        </IconButton>
                        <AccountPopover
                            anchorEl={accountAnchorEl}
                            handleClose={handleCloseAccount}
                        />
                    </div>
                </Toolbar>
            </AppBar>
            <ProjectsDrawer open={openMenu} setOpen={setOpenMenu}/>
        </div>
    );
}

export default withStyles(styles)(MenuAppBar)
