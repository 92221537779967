import React, {createContext, useContext, useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {useTaskUser} from "./TaskUserContext";
import {getProjectsSnapShot} from "../api/projects/getProjects";
import {orderBy} from 'lodash'

export const TaskProjectsContext = createContext({});

export const useProjectsContext = () => {
    return useContext(TaskProjectsContext);
};

export const TaskProjectsProvider = ({children}) => {
    const value = useProjects();
    return (
        <TaskProjectsContext.Provider value={value}>
            {children}
        </TaskProjectsContext.Provider>
    );
};

const useProjects = () => {
    const [user] = useGlobal('user');
    const [projects, setProjects] = useGlobal('projects');
    const {taskUser} = useTaskUser();
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (!user?.user_id)
            return;
        setLoading(true)
        const sub = getProjectsSnapShot(user.user_id, (err, snap) => {
            setLoading(false)
            if (err)
                return;
            let data = snap.docs.map(doc => ({...doc.data(), id: doc.id}));
            setProjects(orderBy(data, 'createdAt', 'desc'))
        });

        return () => {
            if (sub)
                sub();
        };
        // eslint-disable-next-line
    }, [user?.user_id, taskUser]);

    return {
        projects,
        setProjects,
        loading
    };
};
