import {SvgIcon} from "@material-ui/core";

function StepDoubleNext({className, color}) {
    return (
        <SvgIcon className={className} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path
                    d="M11.7185 8.16283L8.41089 4.8552C7.79662 4.24093 6.94608 3.86292 6.0483 3.86292H2.26815C1.70113 3.86292 1.1341 4.05192 0.756089 4.47719C0.330823 4.90246 0.141815 5.46948 0.141815 5.98925V12.888C0.141815 13.455 0.330823 14.0221 0.756089 14.4001C1.18136 14.8726 1.74838 15.1089 2.26815 15.1089H6.0483C6.94608 15.1089 7.79662 14.7781 8.41089 14.1166L11.7185 10.8089C12.4746 10.0529 12.4746 8.87161 11.7185 8.16283Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M23.059 8.11559L19.7513 4.80796C19.1371 4.19369 18.2865 3.81567 17.3887 3.81567H9.82845C9.92295 3.86293 9.9702 3.95743 10.0175 4.00468L13.3251 7.31231C14.5064 8.49361 14.5064 10.3364 13.3251 11.5177L10.0175 14.8254C9.9702 14.8726 9.8757 14.9671 9.82845 15.0144H17.3415C18.2393 15.0144 19.0898 14.6836 19.7041 14.0221L23.0117 10.7144C23.7677 10.0529 23.7677 8.87162 23.059 8.11559Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M2.50442 20.9681C3.75705 20.9681 4.77251 19.9527 4.77251 18.7C4.77251 17.4474 3.75705 16.4319 2.50442 16.4319C1.25179 16.4319 0.236328 17.4474 0.236328 18.7C0.236328 19.9527 1.25179 20.9681 2.50442 20.9681Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M10.112 17.566L5.4813 17.6132C5.62306 17.944 5.71756 18.3693 5.71756 18.7473C5.71756 19.1253 5.62306 19.5506 5.52855 19.8813L10.1592 19.8341C10.7735 19.8341 11.2933 19.3143 11.2933 18.7C11.2933 18.0857 10.7735 17.566 10.112 17.566Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M14.6954 20.9681C15.948 20.9681 16.9635 19.9527 16.9635 18.7C16.9635 17.4474 15.948 16.4319 14.6954 16.4319C13.4427 16.4319 12.4273 17.4474 12.4273 18.7C12.4273 19.9527 13.4427 20.9681 14.6954 20.9681Z"
                    fill={color ?? "#707070"}/>
                <path
                    d="M22.3502 17.566L17.7195 17.6132C17.8613 17.944 17.9558 18.3693 17.9558 18.7473C17.9558 19.1253 17.8613 19.5506 17.7668 19.8813L22.3974 19.8341C23.0117 19.8341 23.5315 19.3143 23.5315 18.7C23.4842 18.0857 22.9645 17.566 22.3502 17.566Z"
                    fill={color ?? "#707070"}/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="23.6259" height="23.6259" fill="white" transform="translate(6.10352e-05 0.460815)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default StepDoubleNext;
