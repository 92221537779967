import fireBase from "../../config/firebaseConfig";
import {getGlobal} from 'reactn'

export default async (file) => {
    const {user} = getGlobal()
    const timeUpload = new Date().getTime()
    const ref = fireBase.storage.ref()
        .child(`tasks/${user.user_id}/${timeUpload}_${file.name}`)
    const snap = await ref.put(file)
    const url = await snap.ref.getDownloadURL()
    return url
}
