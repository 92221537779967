import addproject from "./projects/addproject";
import {getProjects, getProjectsSnapShot} from "./projects/getProjects";
import {getPhases, getPhasesSnap} from "./phases/getPhases";
import {getCategories, getCategoriesSnap} from "./categories/getCategories";
import addCategory from "./categories/addCategory";
import addPhase from "./phases/addPhase";
import uploadImage from "./upload/uploadImage";
import addOutput from "./outputs/addOutput";
import {getOutputs, getOutputsSnap} from "./outputs/getOutputs";
import uploadAttachment from "./upload/uploadAttachment";
import addTask from "./tasks/addTask";
import {getTasks, getTasksSnap} from "./tasks/getTasks";

export default {
    addproject,
    getProjects,
    getProjectsSnapShot,
    getPhasesSnap,
    getPhases,
    getCategories,
    getCategoriesSnap,
    addCategory,
    addPhase,
    uploadImage,
    uploadAttachment,
    addOutput,
    getOutputs,
    getOutputsSnap,
    addTask,
    getTasks,
    getTasksSnap
}
