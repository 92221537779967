import {lazy} from 'react';

// export const TaskListApp = lazy(() => import('../components/TaskListApp/TaskListApp'));
// export const Footer = lazy(() => import('./Footer/Footer'));
// export const MenuAppBar = lazy(() => import('../components/MenuAppBar/MenuAppBar'));
export const Setting = lazy(() => import('../page/Setting'));
export const Home = lazy(() => import('../page/HomePage'));
export const ProjectDashboard = lazy(() => import('../page/ProjectDashboardPage'));
export const List = lazy(() => import('../page/ListPage'));
export const CategoryDashboard = lazy(() => import('../page/CategoryDashboardPage'));
export const NotFound = lazy(() => import('../page/NotFound'));
